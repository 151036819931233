<template>
  <div class="sso-container">
    <div class="loading-content" v-if="!$store.state.token">
      <div class="loading-spinner">
        <svg class="circular" viewBox="25 25 50 50">
          <circle class="path" cx="50" cy="50" r="20" fill="none" />
        </svg>
      </div>
      <p class="loading-text">身份验证中</p>
      <div class="loading-dots">
        <span></span>
        <span></span>
        <span></span>
      </div>
    </div>

  </div>
</template>

<script>
import { Dialog, Toast } from 'vant'
import { SetPvAndUv } from '@/mixins/PvAndUv'
import { api } from '@/api/index'

import service from '@api/service'

// console.log('--------------------------', { service })

export default {
  name: 'Index',
  mixins: [SetPvAndUv],
  data() {
    return {
      ticket: '',
      ticketId: '',
      userId: '',
      userName: '',
      token: '',
      userType: '',
      sp: '',
    }
  },
  mounted() {
    this.sp = this.getQuery('sp') ? this.getQuery('sp') : ''
    this.startTimer()
    this.handleUrl().then(() => {
      // common.DialogStr('有回调访问')
      console.log('回调访问')
    }).catch(() => {
      // common.DialogStr('没有回调访问')
      console.log('没有回调访问')
    })
    // 判断是否登陆
    this.isLogin().then(() => {
      // 已经登录则直接跳转首页
      this.$router.redirect({
        path: this.sp ? '/' + this.sp : '/',
      })
    }).catch(() => {
      // 未登录则进行浙里办统一用户登录
      this.loginZlb()
    })
  },
  methods: {
    /**
     * 登录浙里办
     */
    loginZlb() {
      if (ZWJSBridge.ssoTicket) {
        ZWJSBridge.ssoTicket({}).then((ssoFlag) => {
          if (ssoFlag && ssoFlag.result === true) {
            //使用 IRS“浙里办”单点登录组件
            if (ssoFlag.ticketId) {
              const { ticketId } = ssoFlag
              this.ticketId = ticketId
              // Dialog.confirm({
              //   title: '提示',
              //   message: '您已成功登录:' + this.ticket,
              //   confirmButtonText: '确定',
              //   showCancelButton: false,
              // }).then(() => {
              //   // ZWJSBridge.close()
              //   //个人获取token
              //   this.getTokenByPersonal()
              // })
              this.getTokenByPersonal()

            }
          } else {
            Dialog.alert({
              message: '当前环境不支持“浙里办”统一单点登录',
              confirmButtonText: '确定',
              showCancelButton: false,
            }).then(() => {
              ZWJSBridge.close()
            })
          }
        })
      } else {
        // common.DialogStr('异常情况：ZWJSBridge加载异常')
        Dialog.alert({
          title: '提示',
          message: '异常情况：ZWJSBridge加载异常',
        }).then(() => {
          ZWJSBridge.close()
        })
      }


      return false
    },

    //获取地址上的参数
    // Method to retrieve query parameters from the URL
    getQuery(name) {
      var reg = new RegExp('(^|&)' + name + '=([^&]*)(&|$)')
      var r = window.location.search.substr(1).match(reg)
      if (r != null) return unescape(r[2])
      return null
    },
    //个人获取token
    getTokenByPersonal() {
      api.singleSignOn({
        ticketId: this.ticketId,
        type: 1,
      })
        .then((res) => {
          const { code, result, message } = res
          if (res.code === 200) {
            console.log('个人token成功' + JSON.stringify(result))
            this.$store.commit('changeToken', result.token)
            this.$store.commit('changeZlbUserId', result.userid)
            this.$store.commit('changeUserType', result.userType)
            this.$store.commit('changeUserName', result.username)
            this.$store.commit('changeUserInfo', result)
            localStorage.setItem('token', result.token)
            localStorage.setItem('zlbUserId', result.userid)
            localStorage.setItem('userType', result.userType)
            localStorage.setItem('userName', result.username)
            localStorage.setItem('userInfo', JSON.stringify(result))
            api.addUniqueVisitor()
            // ZWJSBridge.hidePreloader()
            this.handleRedirectUrl().then((res) => {
              console.log('回调地址', res)
            }).catch((error) => {
              this.$router.replace({
                path: this.sp ? '/' + this.sp : '/',
              })
            })

            sensors_sw.login(result.userid)
          } else if (code == 500) {
            ZWJSBridge.hidePreloader()
            Toast(message)
          }
        })
    },

    // 处理登陆前执行回调路径跳转
    handleUrl() {
      return new Promise((resolve, reject) => {
        // 1.判断url是否有redirect参数
        const redirectUrl = this.$route.query.redirect
        // 2.如果有，则存储本地存储中
        if (redirectUrl) {
          localStorage.setItem('redirectUrl', decodeURIComponent(redirectUrl))
          // ZWJSBridge.setLocalStorage({
          //   key: 'redirectUrl',
          //   value: redirectUrl,
          // }).then((result) => {
          //   console.log('redirectUrl', result)
          //   Toast('存储redirectUrl成功')
          //   common.DialogStr(redirectUrl)
          //   resolve(result) // 成功时解析 promise
          // }).catch((error) => {
          //   Toast('存储redirectUrl失败')
          //   console.log(error)
          //   reject(error) // 失败时拒绝 promise
          // })
          console.log('存储redirectUrl成功')
          resolve(redirectUrl) // 成功时解析 promise
        } else {
          console.log('无回调地址')
          reject(new Error('无效的登录链接')) // 当没有回调地址时拒绝 promise
        }
      })
    },
    // 处理登录后执行回调路径跳转
    handleRedirectUrl() {
      return new Promise((resolve, reject) => {
        const redirectUrl = localStorage.getItem('redirectUrl')
        console.log('redirectUrl', redirectUrl)
        if (redirectUrl) {
          // Toast('跳转到' + redirectUrl)
          this.clearRedirectUrl()
          this.$router.push({
            path: redirectUrl,
          })
          resolve(redirectUrl) // 成功时解析 promise
        } else {
          console.log('无回调地址')
          this.$router.push({
            path: this.sp ? '/' + this.sp : '/',
          })
          reject(new Error('无效的登录链接')) // 当没有回调地址时拒绝 promise
        }
        reject(new Error('无效的登录链接')) // 当没有回调地址时拒绝 promise

      })
    },
    //清除本地存储的redirectUrl
    clearRedirectUrl() {
      localStorage.removeItem('redirectUrl')
    },
    startTimer() {
      this.timer = setTimeout(() => {
        if (!this.$store.state.token) {
          // Toast('身份验证失败，请查看浙里办登录状态后重新进入');
          Dialog({
            message: '身份验证失败，请查看浙里办登录状态后重新进入',
            confirmButtonText: '确定',
          }).then(() => {
            window.location.reload()
          })

        }
      }, 20000) // 20000 毫秒 = 20 秒
    },

    // 当用户进行某些操作时，重置定时器
    resetTimer() {
      clearTimeout(this.timer)
      this.startTimer()
    },
    isLogin() {
      return new Promise((resolve, reject) => {
        this.token = this.getQuery('token') ? this.getQuery('token') : ''
        if (this.token){
          this.$store.commit('changeToken', this.token)
          resolve(this.token)
        }
        else if (this.$store.state.token){
          resolve(this.$store.state.token)
        }
        else {
          reject(new Error('token不存在'))
        }
      })
    },
  },
  // 在组件销毁时清除定时器
  beforeDestroy() {
    clearTimeout(this.timer)
  },

}
</script>

<style scoped lang="less">
.sso-container {
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background: linear-gradient(135deg, #f5f7fa 0%, #e4ecf7 100%);
}

.loading-content {
  text-align: center;
  animation: fadeIn 0.5s ease;
}

.loading-text {
  color: #2c3e50;
  font-size: @font-size-base;
  margin-top: 20px;
  font-weight: 500;
}

.loading-spinner {
  position: relative;
  width: 50px;
  height: 50px;
  margin: 0 auto;
}

.circular {
  animation: rotate 2s linear infinite;
  height: 100%;
  transform-origin: center center;
  width: 100%;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
}

.path {
  stroke-dasharray: 1, 200;
  stroke-dashoffset: 0;
  animation: dash 1.5s ease-in-out infinite;
  stroke-linecap: round;
  stroke: #409eff;
  stroke-width: 4;
}

.loading-dots {
  margin-top: 15px;

  span {
    display: inline-block;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background: #409eff;
    margin: 0 3px;
    opacity: 0.6;

    &:nth-child(1) {
      animation: dot 1s infinite 0s;
    }

    &:nth-child(2) {
      animation: dot 1s infinite 0.2s;
    }

    &:nth-child(3) {
      animation: dot 1s infinite 0.4s;
    }
  }
}

@keyframes rotate {
  100% {
    transform: rotate(360deg);
  }
}

@keyframes dash {
  0% {
    stroke-dasharray: 1, 200;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -35px;
  }
  100% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -124px;
  }
}

@keyframes dot {
  0%, 100% {
    opacity: 0.6;
    transform: scale(1);
  }
  50% {
    opacity: 1;
    transform: scale(1.2);
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(-10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
</style>
